const data = [
  {
    id: 1,
    title: 'Dark Mode / Theme Switcher',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    tecnologys: ['React JS', 'TypeScript', 'Mirage JS'],
    repository: 'https://www.github.com/kleytonsolinho',
    demo: 'https://www.kleytonsolinho.com/',
  },
  {
    id: 2,
    title: 'Dark Mode / Theme Switcher',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    tecnologys: ['React JS', 'TypeScript', 'Mirage JS'],
    repository: 'https://www.github.com/kleytonsolinho',
    demo: 'https://www.kleytonsolinho.com/',
  },
  {
    id: 3,
    title: 'Dark Mode / Theme Switcher',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    tecnologys: ['React JS', 'TypeScript', 'Mirage JS'],
    repository: 'https://www.github.com/kleytonsolinho',
    demo: 'https://www.kleytonsolinho.com/',
  },
  {
    id: 4,
    title: 'Dark Mode / Theme Switcher',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    tecnologys: ['React JS', 'TypeScript', 'Mirage JS'],
    repository: 'https://www.github.com/kleytonsolinho',
    demo: 'https://www.kleytonsolinho.com/',
  },
  {
    id: 5,
    title: 'Dark Mode / Theme Switcher',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    tecnologys: ['React JS', 'TypeScript', 'Mirage JS'],
    repository: 'https://www.github.com/kleytonsolinho',
    demo: 'https://www.kleytonsolinho.com/',
  },
  {
    id: 6,
    title: 'Dark Mode / Theme Switcher',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    tecnologys: ['React JS', 'TypeScript', 'Mirage JS'],
    repository: 'https://www.github.com/kleytonsolinho',
    demo: 'https://www.kleytonsolinho.com/',
  },
];
export default data;
